import axiosInstance from "../../api";

export const getMedicalReports = (params) => {
  return axiosInstance
    .get("/api/v1/scan/", {
      params,
    })
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      return Promise.reject(error.response ? error.response.data : error);
    });
};

export const getMedicalReportsAutocomplete = (
  query,
  params: { offset: number; limit: number }
) => {
  return axiosInstance
    .get("/api/v1/scan-autocomplete/", {
      params: { q: query, limit: params.limit, offset: params.offset },
    })
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      return Promise.reject(error.response ? error.response.data : error);
    });
};

export const getHospitalPatients = (params?) => {
  return axiosInstance
    .get("/api/v1/reporting/patients", {
      params,
    })
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      return Promise.reject(error.response ? error.response.data : error);
    });
};

export const getHospitalPatientsAutocomplete = (
  query,
  params: { offset: number; limit: number }
) => {
  return axiosInstance
    .get("/api/v1/reporting/patients/autocomplete", {
      params: { q: query, limit: params.limit, offset: params.offset },
    })
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      return Promise.reject(error.response ? error.response.data : error);
    });
};

export const getPatientsDetails = (patientId, detailPage) => {
  if (detailPage === "gaucher_medications") {
    detailPage = "medications";
  }
  return axiosInstance
    .get(`/api/v1/patient/${patientId}/${detailPage}`)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      return Promise.reject(error.response ? error.response.data : error);
    });
};
