export const statusCodes = {
  UNAUTHORIZED: 401,
  FORBIDDEN: 403,
};

export const SUPPORT_EMAIL = "support@lifeonkey.com";

export const PATIENT_RESOURCES = {
  allergies: "allergies",
  diseases: "diseases",
  immunizations: "immunizations",
  medications: "medications",
  gaucher_medications: "gaucher-medications",
  procedures: "procedures",
  symptoms: "symptoms",
};

export const SPECIAL_RESOURCES = {
  laboratoryTests: "laboratory-tests",
};
